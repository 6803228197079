import React from "react";
import sketch from "../../assets/sketch.png";
import StudentCorner from "../../pages/Students' Corner/StudentCorner";
// import "./noticeBoard.css"

const data = [
  {
    name: "Notice Board",
    position: 1,
    items: [
      { text: "asdasdasdas", subtext: "asdihasiudhiashdasdjkahsdkjbasd" },
      { text: "asdasdasdas", subtext: "asdihasiudhiashdasdjkahsdkjbasd" },
      { text: "asdasdasdas", subtext: "asdihasiudhiashdasdjkahsdkjbasd" },
      { text: "asdasdasdas", subtext: "asdihasiudhiashdasdjkahsdkjbasd" },
      { text: "asdasdasdas", subtext: "asdihasiudhiashdasdjkahsdkjbasd" },
    ],
  },
  {
    name: "Events",
    position: 2,
    items: [
      {
        text: "events asdasdasdas",
        subtext: "asdihasiudhiashdasdjkahsdkjbasd",
      },
      {
        text: "events asdasdasdas",
        subtext: "asdihasiudhiashdasdjkahsdkjbasd",
      },
      {
        text: "events asdasdasdas",
        subtext: "asdihasiudhiashdasdjkahsdkjbasd",
      },
      {
        text: "events asdasdasdas",
        subtext: "asdihasiudhiashdasdjkahsdkjbasd",
      },
      {
        text: "events asdasdasdas",
        subtext: "asdihasiudhiashdasdjkahsdkjbasd",
      },
    ],
  },
  {
    name: "Tender",
    position: 3,
    items: [
      {
        text: "tender asdasdasdas",
        subtext: "asdihasiudhiashdasdjkahsdkjbasd",
      },
      {
        text: "tender asdasdasdas",
        subtext: "asdihasiudhiashdasdjkahsdkjbasd",
      },
      {
        text: "tender asdasdasdas",
        subtext: "asdihasiudhiashdasdjkahsdkjbasd",
      },
      {
        text: "tender asdasdasdas",
        subtext: "asdihasiudhiashdasdjkahsdkjbasd",
      },
      {
        text: "tender asdasdasdas",
        subtext: "asdihasiudhiashdasdjkahsdkjbasd",
      },
    ],
  },
];

const NoticeBoard = () => {
  const [openTab, setOpenTab] = React.useState(1);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6 align-middle justify-center">
      <div className="max-w-2xl bg-white rounded-lg border shadow-md mt-7">
        <ul
          role="tablist"
          className="flex flex-wrap text-sm font-medium text-center custom-bg rounded-t-lg border-b border-gray-200"
          id="defaultTab"
        >
          {data.map((item) => {
            return (
              <>
                <li key={item.name} className="mr-2">
                  <a
                    id={item.name + "tab"}
                    className={
                      (openTab === item.position ? "text-red-700" : "black") +
                      " font-semibold inline-block p-4 rounded-tl-lg hover:bg-orange-200"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(item.position);
                    }}
                    data-toggle="tab"
                    role="tablist"
                    href={"#" + item.name}
                  >
                    {item.name}
                  </a>
                </li>
              </>
            );
          })}
        </ul>
        {data.map((item) => {
          return (
            <div className="tab-content tab-space">
              <div
                className={
                  (openTab === item.position ? "block" : "hidden") +
                  " p-4 bg-white rounded-lg md:p-2"
                }
                id={item.name}
                role="tabpanel"
                aria-labelledby={item.name + "-tab"}
                style={{
                  backgroundImage: `url(${sketch})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <p className="text-gray-500">
                  <ul className="divide-y divide-gray-200 max-w-full flex flex-col text-gray-700">
                    {item.items.map((item) => {
                      return (
                        <>
                          <li className="py-3 pr-1 md:pr-3">
                            <div className="flex items-center space-x-4">
                              <div className="flex-shrink-0">
                                <img
                                  alt="bullet"
                                  src="https://uxwing.com/wp-content/themes/uxwing/download/arrow-direction/angle-right-icon.svg"
                                  width="10px"
                                  className="ml-4 mr-2"
                                />
                              </div>
                              <div className="grow min-w-0">
                                <p className="text-sm font-medium text-gray-900 truncate">
                                  {item.text}
                                </p>
                                <p className="text-sm text-gray-500 truncate">
                                  {item.subtext}
                                </p>
                              </div>
                              <div className="inline-flex font-medium items-center text-gray-700">
                                2hrs ago
                              </div>
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <StudentCorner />
    </div>
  );
};

export default NoticeBoard;

// <div>
//   <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-6 align-middle justify-center">
//     {data.map((board) => {
//       return (
//         <>
//           <div className="max-w-2xl bg-white rounded-lg border border-gray-200 shadow-md">
//             <div
//               style={{
//                 backgroundImage: `url(${sketch})`,
//                 backgroundRepeat: "no-repeat",
//                 backgroundSize: "cover",
//               }}
//             >
//               <div className="custom-bg bg-cover rounded-t-lg">
//                 <h5 className="p-2 mb-2 text-2xl font-bold tracking-tight dark:text-white text-center overflow-hidden">
//                   {board.name}
//                 </h5>
//               </div>
//               <ul className="px-6 py-4 space-y-1 max-w-md flex flex-col text-gray-700">
//                 {board.items.map((item) => {
//                   return (
//                     <>
//                       <li className="flex flex-row items-center py-3">
//                         <img
//                           alt="bullet"
//                           src="https://uxwing.com/wp-content/themes/uxwing/download/arrow-direction/angle-right-icon.svg"
//                           width="10px"
//                           className="mr-2"
//                         />
//                         {item.text}
//                       </li>
//                     </>
//                   );
//                 })}
//               </ul>
//             </div>
//           </div>
//         </>
//       );
//     })}
//   </div>
// </div>
