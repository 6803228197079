import React from "react";

const gallery_data = {
  photos: [
    {
      name: "Random image",
      src: "https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp",
    },
    {
      name: "Random image",
      src: "https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp",
    },
    {
      name: "Random image",
      src: "https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp",
    },
    {
      name: "Random image",
      src: "https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp",
    },
    {
      name: "Random image",
      src: "https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp",
    },
    {
      name: "Random image",
      src: "https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp",
    },
  ],
};

const Gallery = () => {
  return (
    <section className="text-gray-600">
      <div className="container px-5 py-12 mx-auto">
        <div className="flex flex-col text-center w-full mb-10">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900">
            Gallery
          </h1>
        </div>
        <div className="flex flex-wrap -m-4">
          {gallery_data.photos.map((elem) => {
            return (
              <div key={elem.name} className="lg:w-1/3 w-1/2 p-4">
                {/*eslint-disable-next-line */}
                <a href="#">
                  <div className="flex relative">
                    <img
                      alt="gallery"
                      className="absolute inset-0 w-full h-full object-cover object-center"
                      src={elem.src}
                    />
                    <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-200 custom-bg opacity-0 hover:opacity-80">
                      <h2 className="tracking-widest text-sm title-font font-medium text-black mb-1">
                        THE SUBTITLE
                      </h2>
                      <h1 className="title-font text-lg font-bold text-black hover:text-orange-700 mb-3">
                        Shooting Stars
                      </h1>
                      <p className="leading-relaxed text-black">
                        Photo booth fam kinfolk cold-pressed sriracha leggings
                        jianbing microdosing tousled waistcoat.
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Gallery;
