import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import Navigation from "./components/Navigation/Navigation";
import "./infosection.css"
import About from "./pages/About/About";
import Director from "./components/Director/Director";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<></>} />
                <Route path="/:any" element={<Navigation common />} />
            </Routes>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/about" element={<About truncate={false} />} />
                <Route exact path="/directors_message" element={<Director truncate={false} />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
