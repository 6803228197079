import classes from "./Home.module.css";

import Header from "../../components/Header/Header";
import NoticeBoard from "../../components/Board/NoticeBoard";
import ImageCarousel from "../../components/Carousel/ImageCarousel";
import Infosection from "../../components/Infosection/Infosection";
import Director from "../../components/Director/Director";
import Stats from "../../components/Stats/Stats";
import About from "../About/About";
import Gallery from "../../components/Gallery/Gallery";

export default function Home2() {
    return (
        <>
            <Header />
            <div id="explore" className={classes.afterHeader}>
                <NoticeBoard />
                <ImageCarousel />
                <Infosection />
                <About truncate={true} />
                <Director truncate={true} />
                <Stats />
                <Gallery />
            </div>
        </>
    );
}


/*
- Navbar
- Carousel of recent events/relevant news: refer(https://www.iiitd.ac.in/)
- Notice Board | News and Events | Tender: refer: (https://www.iiit-bh.ac.in/)
- Departments: refer(https://www.iiitd.ac.in/#:~:text=Technology%20in%20Policing-,Programs,-FREE)
- Gallery
- About
- Footer
*/