import classes from "./Header.module.css";
import Navigation from "../Navigation/Navigation";
import { FaAngleDown } from "react-icons/fa";
import { useState, useEffect } from 'react';

export default function Home2() {
    const [hideVideo, setHideVideo] = useState(false);

    const handleScrollVideo = () => {
        const position = window.pageYOffset;
        if (position > window.innerHeight) setHideVideo(true);
        else if (hideVideo) setHideVideo(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrollVideo, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScrollVideo);
        };
    });

    return (
        <div className={classes.videoWrap}>
            <div className={classes.topShadow} />
            <div className={classes.navBar}>
                <Navigation />
            </div>
            <video loop muted autoplay="autoplay" src="./images/BackDropVideo.mp4" type="video/mp4" />
            <a href="#explore" ><div className={classes.explore}><span>Explore IIIT Bhubaneswar<FaAngleDown className={classes.icon} /></span></div></a>
        </div>
    );
}