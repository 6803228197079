import React from 'react'

function Infosection() {
    return (
        <section className="text-gray-600  parallax-background" >
            <div className="container px-5 py-24 mx-auto" >

                <div className="flex flex-wrap -m-4" >
                    <div className="xl:w-1/3 md:w-1/2 p-4 " >
                        <div className="border border-gray-200 p-6 rounded-lg small-boxes " >

                            <h2 className="text-lg text-gray-900 font-medium title-font mb-2" >About us</h2>
                            <p className="leading-relaxed text-base" >International Institute of Information Technology, Bhubaneswar was established by the Government of Odisha in 2006. It is designed to be a premier institute of Information Technology and related areas.</p>
                        </div>
                    </div>
                    <div className="xl:w-1/3 md:w-1/2 p-4" >
                        <div className="border border-gray-200 p-6 rounded-lg small-boxes" >

                            <h2 className="text-lg text-gray-900 font-medium title-font mb-2" >Academics</h2>
                            <p className="leading-relaxed text-base" >We offer undergraduate, graduate and doctoral programmes in Computer Science, Electronics Engineering, Electrical Engineering. The Doctoral Programme is offered in Science,</p>
                        </div>
                    </div>
                    <div className="xl:w-1/3 md:w-1/2 p-4" >
                        <div className="border border-gray-200 p-6 rounded-lg small-boxes" >
                            <h2 className="text-lg text-gray-900 font-medium title-font mb-2" >Corporate Relations</h2>
                            <p className="leading-relaxed text-base" >For corporations and the government, the Institute offers its students for recruitment, its faculty resources for sponsored research and consulting. The Institute appreciates the Institute constantly looks for ta </p>
                        </div>
                    </div>
                    {/* <div className="xl:w-1/3 md:w-1/2 p-4" >
                        <div className="border border-gray-200 p-6 rounded-lg small-boxes" >
                            <h2 className="text-lg text-gray-900 font-medium title-font mb-2" >Faculty</h2>
                            <p className="leading-relaxed text-base" >The Institute owes its excellence to its faculty who are responsible for quality in research, education and culture of innovation and free thinking.The Institute constantly looks for talented.</p>
                        </div>
                    </div>
                    <div className="xl:w-1/3 md:w-1/2 p-4" >
                        <div className="border border-gray-200 p-6 rounded-lg small-boxes" >

                            <h2 className="text-lg text-gray-900 font-medium title-font mb-2" >Prospective Students </h2>
                            <p className="leading-relaxed text-base" >The Institute provides an extraordinary environment to create technology leaders of tomorrow and help them face the challenges of tomorrow.The Institute constantly looks for talented Faculty to bolster its  </p>
                        </div>
                    </div>
                    <div className="xl:w-1/3 md:w-1/2 p-4" >
                        <div className="border border-gray-200 p-6 rounded-lg small-boxes" >

                            <h2 className="text-lg text-gray-900 font-medium title-font mb-2" >Jobs Opportunities</h2>
                            <p className="leading-relaxed text-base" >The Institute constantly looks for talented Faculty to bolster its teaching and research activities. The academic environment empowers and encourages its faculty members to innovate and initiate</p>
                        </div>
                    </div> */}
                </div>

            </div>
        </section>
    )
}

export default Infosection